import { defineComponent, h } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { RouteList } from '@/routes/RouteList'
import { MenuCode } from '@/shared'
import { AdminAbilityEnum } from '@/enums/AdminAbilityEnum'

const DashboardMain = () => import('@/components/Dashboard/DashboardMain.vue')
const SubSideBar = () => import('@/components/Layout/SubSideBar.vue')
const BackOfficeNavBar = () => import('@/components/Layout/BackOffice/NavBar.vue')

const BusinessList = () => import('@/components/BackOffice/Business/BusinessList.vue')
const BusinessSingle = () => import('@/components/BackOffice/Business/BusinessSingle.vue')
const BusinessProfile = () => import('@/components/BackOffice/Business/BusinessProfile.vue')
const BusinessOther = () => import('@/components/BackOffice/Business/BusinessOther.vue')
const UserList = () => import('@/components/BackOffice/Users/UserList.vue')
const AdminsList = () => import('@/components/BackOffice/Admin/AdminsList.vue')
const RolesList = () => import('@/components/BackOffice/Roles/RolesList.vue')
const SubscriptionsList = () =>
  import('@/components/BackOffice/Subscriptions/SubscriptionsList.vue')
const SubscriptionsSingle = () =>
  import('@/components/BackOffice/Subscriptions/SubscriptionsSingle.vue')
const ServicesList = () => import('@/components/BackOffice/Services/ServicesList.vue')
const FeaturesList = () => import('@/components/BackOffice/Features/FeaturesList.vue')
const InstallationPackages = () =>
  import('@/views/BackOffice/Platform/InstallationPackagesView.vue')
const Configuration = () =>
  import('@/components/BackOffice/Configuration/BusinessConfiguration.vue')
const EmailThemeList = () => import('@/components/BackOffice/Emails/EmailThemeList.vue')
const EmailDesignList = () => import('@/components/BackOffice/Emails/EmailDesignList.vue')
const LogsList = () => import('@/components/BackOffice/Logs/LogsList.vue')

const Integrations = {
  Twilio: () => import('@/views/BackOffice/Integrations/Twilio/TwilioIntegrationView.vue'),
  Mailgun: () => import('@/components/BackOffice/Integrations/MailgunIntegration.vue'),
  Intercom: () => import('@/components/BackOffice/Integrations/IntercomIntegration.vue'),
  Google: () => import('@/components/BackOffice/Integrations/GoogleIntegration.vue'),
  BeeFree: () => import('@/components/BackOffice/Integrations/BeeFreeIntegration.vue'),
  Facebook: () => import('@/components/BackOffice/Integrations/FacebookIntegration.vue'),
  Microsoft: () => import('@/components/BackOffice/Integrations/MicrosoftIntegration.vue'),
  Zoom: () => import('@/components/BackOffice/Integrations/ZoomIntegration.vue'),
  LookerStudio: () => import('@/components/BackOffice/Integrations/LookerStudioIntegration.vue'),
  ProcareDesktop: () =>
    import('@/components/BackOffice/Integrations/ProcareDesktopIntegration.vue'),
  Kangarootime: () => import('@/components/BackOffice/Integrations/KangarootimeIntegration.vue'),
  Finix: () => import('@/components/BackOffice/Integrations/FinixIntegration.vue'),
  Care: () => import('@/components/BackOffice/Integrations/CareIntegration.vue'),
  Nutrient: () => import('@/components/BackOffice/Integrations/NutrientIntegration.vue'),
  Famly: () => import('@/components/BackOffice/Integrations/FamlyIntegration.vue'),
  Winnie: () => import('@/components/BackOffice/Integrations/WinnieIntegration.vue'),
  ProcareOnline: () => import('@/components/BackOffice/Integrations/ProcareOnlineIntegration.vue'),
  ProcareOnlinePrimrose: () =>
    import('@/components/BackOffice/Integrations/ProcareOnlinePrimroseIntegration.vue'),
  ApiV2: () => import('@/components/BackOffice/Integrations/ApiV2Integration.vue'),
  OnRamp: () => import('@/components/BackOffice/Integrations/OnRampIntegration.vue'),
  TalentLms: () => import('@/components/BackOffice/Integrations/TalentLmsIntegration.vue'),
  OpenAi: () => import('@/components/BackOffice/Integrations/OpenAiIntegration.vue'),
  IntellikidsAi: () => import('@/components/BackOffice/Integrations/IntellikidsAiIntegration.vue'),
}

const Migrations = () =>
  import('@/views/BackOffice/Integrations/Migrations/MigrationsIntegrationView.vue')
const MigrationsBusinesses = () =>
  import('@/views/BackOffice/Integrations/Migrations/MigrationsBusinessesView.vue')
const MigrationsLocations = () =>
  import('@/views/BackOffice/Integrations/Migrations/MigrationsLocationsView.vue')
const MigrationsUsers = () =>
  import('@/views/BackOffice/Integrations/Migrations/MigrationsUsersView.vue')
const MigrationsOther = () =>
  import('@/views/BackOffice/Integrations/Migrations/MigrationsOtherView.vue')

const DivComponent = defineComponent({ render: () => h('div') })

export default <RouteRecordRaw[]>[
  {
    path: RouteList.BACK_OFFICE.INDEX.PATH,
    name: RouteList.BACK_OFFICE.INDEX.NAME,
    components: {
      default: DashboardMain,
      SideBar: SubSideBar,
      NavBar: BackOfficeNavBar,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.BackOffice,
        title: 'Back Office',
      },
    },
    meta: {
      requiresAuth: true,
      admin: true,
    },
    redirect: {
      name: RouteList.BACK_OFFICE.BUSINESSES.INDEX.NAME,
    },
    children: [
      {
        path: RouteList.BACK_OFFICE.BUSINESSES.INDEX.PATH,
        name: RouteList.BACK_OFFICE.BUSINESSES.INDEX.NAME,
        component: BusinessList,
        meta: {
          permission: AdminAbilityEnum.BUSINESS,
        },
      },
      {
        path: RouteList.BACK_OFFICE.BUSINESSES.BUSINESS.INDEX.PATH,
        name: RouteList.BACK_OFFICE.BUSINESSES.BUSINESS.INDEX.NAME,
        component: BusinessSingle,
        meta: {
          permission: AdminAbilityEnum.BUSINESS_VIEW,
        },
        redirect: {
          name: RouteList.BACK_OFFICE.BUSINESSES.BUSINESS.PROFILE.NAME,
        },
        children: [
          {
            path: RouteList.BACK_OFFICE.BUSINESSES.BUSINESS.PROFILE.PATH,
            name: RouteList.BACK_OFFICE.BUSINESSES.BUSINESS.PROFILE.NAME,
            component: BusinessProfile,
            meta: {
              permission: AdminAbilityEnum.BUSINESS_VIEW,
            },
          },
          {
            path: RouteList.BACK_OFFICE.BUSINESSES.BUSINESS.COMPANY.PATH,
            name: RouteList.BACK_OFFICE.BUSINESSES.BUSINESS.COMPANY.NAME,
            component: DivComponent,
            meta: {
              permission: AdminAbilityEnum.BUSINESS_VIEW,
            },
          },
          {
            path: RouteList.BACK_OFFICE.BUSINESSES.BUSINESS.BALANCE.PATH,
            name: RouteList.BACK_OFFICE.BUSINESSES.BUSINESS.BALANCE.NAME,
            component: DivComponent,
            meta: {
              permission: AdminAbilityEnum.BUSINESS_VIEW,
            },
          },
          {
            path: RouteList.BACK_OFFICE.BUSINESSES.BUSINESS.OTHER.PATH,
            name: RouteList.BACK_OFFICE.BUSINESSES.BUSINESS.OTHER.NAME,
            component: BusinessOther,
            meta: {
              permission: AdminAbilityEnum.BUSINESS_VIEW,
            },
          },
        ],
      },
      {
        path: RouteList.BACK_OFFICE.USERS.PATH,
        name: RouteList.BACK_OFFICE.USERS.NAME,
        component: UserList,
        meta: {
          permission: AdminAbilityEnum.USER,
        },
      },
      {
        path: RouteList.BACK_OFFICE.ADMINS.PATH,
        name: RouteList.BACK_OFFICE.ADMINS.NAME,
        component: AdminsList,
        meta: {
          permission: [AdminAbilityEnum.ADMIN_ADMINS, AdminAbilityEnum.ADMIN],
        },
      },
      {
        path: RouteList.BACK_OFFICE.ROLES.PATH,
        name: RouteList.BACK_OFFICE.ROLES.NAME,
        component: RolesList,
        meta: {
          permission: [AdminAbilityEnum.ADMIN_ROLES, AdminAbilityEnum.ADMIN],
        },
      },
      {
        path: RouteList.BACK_OFFICE.SUBSCRIPTIONS.INDEX.PATH,
        name: RouteList.BACK_OFFICE.SUBSCRIPTIONS.INDEX.NAME,
        component: SubscriptionsList,
        meta: {
          permission: [AdminAbilityEnum.SUBSCRIPTION_SUBSCRIPTIONS, AdminAbilityEnum.SUBSCRIPTION],
        },
      },
      {
        path: RouteList.BACK_OFFICE.SUBSCRIPTIONS.SINGLE.PATH,
        name: RouteList.BACK_OFFICE.SUBSCRIPTIONS.SINGLE.NAME,
        component: SubscriptionsSingle,
        meta: {
          permission: [
            AdminAbilityEnum.SUBSCRIPTION_SUBSCRIPTIONS_VIEW,
            AdminAbilityEnum.SUBSCRIPTION,
          ],
        },
      },
      {
        path: RouteList.BACK_OFFICE.SERVICES.PATH,
        name: RouteList.BACK_OFFICE.SERVICES.NAME,
        component: ServicesList,
        meta: {
          permission: [AdminAbilityEnum.SUBSCRIPTION_SERVICES, AdminAbilityEnum.SUBSCRIPTION],
        },
      },
      {
        path: RouteList.BACK_OFFICE.FEATURES.PATH,
        name: RouteList.BACK_OFFICE.FEATURES.NAME,
        component: FeaturesList,
        meta: {
          permission: AdminAbilityEnum.FEATURE,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INSTALLATION.PATH,
        name: RouteList.BACK_OFFICE.INSTALLATION.NAME,
        component: InstallationPackages,
        meta: {
          permission: AdminAbilityEnum.INSTALLATION_PACKAGE,
        },
      },
      {
        path: RouteList.BACK_OFFICE.CONFIGURATION.PATH,
        name: RouteList.BACK_OFFICE.CONFIGURATION.NAME,
        component: Configuration,
        meta: {
          permission: AdminAbilityEnum.CONFIGURATION,
        },
      },
      {
        path: RouteList.BACK_OFFICE.EMAIL.THEMES.PATH,
        name: RouteList.BACK_OFFICE.EMAIL.THEMES.NAME,
        component: EmailThemeList,
        meta: {
          permission: AdminAbilityEnum.EMAIL_VIEW,
        },
      },
      {
        path: RouteList.BACK_OFFICE.EMAIL.DESIGNS.PATH,
        name: RouteList.BACK_OFFICE.EMAIL.DESIGNS.NAME,
        component: EmailDesignList,
        meta: {
          permission: AdminAbilityEnum.EMAIL_VIEW,
        },
      },
      {
        path: RouteList.BACK_OFFICE.LOGS.PATH,
        name: RouteList.BACK_OFFICE.LOGS.NAME,
        component: LogsList,
        meta: {
          permission: AdminAbilityEnum.LOG,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.TWILIO.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.TWILIO.NAME,
        component: Integrations.Twilio,
        meta: {
          permission: AdminAbilityEnum.INTEGRATION_TWILIO,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.MAILGUN.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.MAILGUN.NAME,
        component: Integrations.Mailgun,
        meta: {
          permission: AdminAbilityEnum.INTEGRATION_MAILGUN,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.INTERCOM.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.INTERCOM.NAME,
        component: Integrations.Intercom,
        meta: {
          permission: AdminAbilityEnum.INTEGRATION_INTERCOM,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.GOOGLE.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.GOOGLE.NAME,
        component: Integrations.Google,
        meta: {
          permission: AdminAbilityEnum.INTEGRATION_GOOGLE,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.BEEFREE.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.BEEFREE.NAME,
        component: Integrations.BeeFree,
        meta: {
          permission: AdminAbilityEnum.INTEGRATION_BEEFREE,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.FACEBOOK.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.FACEBOOK.NAME,
        component: Integrations.Facebook,
        meta: {
          permission: AdminAbilityEnum.INTEGRATION_FACEBOOK,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.MICROSOFT.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.MICROSOFT.NAME,
        component: Integrations.Microsoft,
        meta: {
          permission: AdminAbilityEnum.INTEGRATION_MICROSOFT,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.ZOOM.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.ZOOM.NAME,
        component: Integrations.Zoom,
        meta: {
          permission: AdminAbilityEnum.INTEGRATION_ZOOM,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.LOOKER_STUDIO.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.LOOKER_STUDIO.NAME,
        component: Integrations.LookerStudio,
        meta: {
          permission: AdminAbilityEnum.INTEGRATION_LOOKER_STUDIO,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.PROCARE.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.PROCARE.NAME,
        component: Integrations.ProcareDesktop,
        meta: {
          permission: AdminAbilityEnum.INTEGRATION_PROCARE,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.KANGAROOTIME.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.KANGAROOTIME.NAME,
        component: Integrations.Kangarootime,
        meta: {
          permission: AdminAbilityEnum.INTEGRATION_KANGAROOTIME,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.FINIX.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.FINIX.NAME,
        component: Integrations.Finix,
        meta: {
          permission: AdminAbilityEnum.INTEGRATION_FINIX,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.CARE.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.CARE.NAME,
        component: Integrations.Care,
        meta: {
          permission: AdminAbilityEnum.INTEGRATION_CARE,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.NUTRIENT.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.NUTRIENT.NAME,
        component: Integrations.Nutrient,
        meta: {
          permission: AdminAbilityEnum.INTEGRATION_PSPDFKIT,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.FAMLY.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.FAMLY.NAME,
        component: Integrations.Famly,
        meta: {
          // permission: AdminAbilityEnum.INTEGRATION_FAMLY,
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.WINNIE.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.WINNIE.NAME,
        component: Integrations.Winnie,
        meta: {
          // permission: AdminAbilityEnum.INTEGRATION_WINNIE, check integration name before uncommenting
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.PROCARE_ONLINE.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.PROCARE_ONLINE.NAME,
        component: Integrations.ProcareOnline,
        meta: {
          // permission: AdminAbilityEnum.INTEGRATION_PROCARE_ONLINE, check integration name before uncommenting
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.PROCARE_ONLINE_PRIMROSE.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.PROCARE_ONLINE_PRIMROSE.NAME,
        component: Integrations.ProcareOnlinePrimrose,
        meta: {
          // permission: AdminAbilityEnum.INTEGRATION_PROCARE_ONLINE_PRIMROSE, check integration name before uncommenting
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.APIV2.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.APIV2.NAME,
        component: Integrations.ApiV2,
        meta: {
          // permission: AdminAbilityEnum.INTEGRATION_APIV2, check integration name before uncommenting
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.ONRAMP.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.ONRAMP.NAME,
        component: Integrations.OnRamp,
        meta: {
          // permission: AdminAbilityEnum.INTEGRATION_ONRAMP, check integration name before uncommenting
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.TALENT_LMS.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.TALENT_LMS.NAME,
        component: Integrations.TalentLms,
        meta: {
          // permission: AdminAbilityEnum.INTEGRATION_TALENT_LMS, check integration name before uncommenting
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.OPENAI.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.OPENAI.NAME,
        component: Integrations.OpenAi,
        meta: {
          // permission: AdminAbilityEnum.INTEGRATION_OPENAI, check integration name before uncommenting
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.INTELLIKIDSAI.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.INTELLIKIDSAI.NAME,
        component: Integrations.IntellikidsAi,
        meta: {
          // permission: AdminAbilityEnum.INTEGRATION_INTELLIKIDSAI, check integration name before uncommenting
        },
      },
      {
        path: RouteList.BACK_OFFICE.INTEGRATIONS.MIGRATION.INDEX.PATH,
        name: RouteList.BACK_OFFICE.INTEGRATIONS.MIGRATION.INDEX.NAME,
        component: Migrations,
        meta: {
          // permission: AdminAbilityEnum.MIGRATIONS
        },
        redirect: {
          name: RouteList.BACK_OFFICE.INTEGRATIONS.MIGRATION.BUSINESSES.NAME,
        },
        children: [
          {
            path: RouteList.BACK_OFFICE.INTEGRATIONS.MIGRATION.BUSINESSES.PATH,
            name: RouteList.BACK_OFFICE.INTEGRATIONS.MIGRATION.BUSINESSES.NAME,
            component: MigrationsBusinesses,
            meta: {
              // permission: AdminAbilityEnum.BUSINESS_VIEW
            },
          },
          {
            path: RouteList.BACK_OFFICE.INTEGRATIONS.MIGRATION.LOCATIONS.PATH,
            name: RouteList.BACK_OFFICE.INTEGRATIONS.MIGRATION.LOCATIONS.NAME,
            component: MigrationsLocations,
            meta: {
              // permission: AdminAbilityEnum.BUSINESS_VIEW
            },
          },
          {
            path: RouteList.BACK_OFFICE.INTEGRATIONS.MIGRATION.USERS.PATH,
            name: RouteList.BACK_OFFICE.INTEGRATIONS.MIGRATION.USERS.NAME,
            component: MigrationsUsers,
            meta: {
              // permission: AdminAbilityEnum.BUSINESS_VIEW
            },
          },
          {
            path: RouteList.BACK_OFFICE.INTEGRATIONS.MIGRATION.OTHER.PATH,
            name: RouteList.BACK_OFFICE.INTEGRATIONS.MIGRATION.OTHER.NAME,
            component: MigrationsOther,
            meta: {
              // permission: AdminAbilityEnum.BUSINESS_VIEW
            },
          },
        ],
      },
    ],
  },
]
