import store from '@/store'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Locale } from '@/shared'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(duration)

// TODO apply localization to dates, because US format does not apply to every business
// Also separate between long/short dates and time
const SHORT_DATE = 'M/D/YY' // in US format
// const LONG_DATE = 'M/D/YYYY' // in US format
const DATE_TIME = 'h:mm A' // in US format

export function getBusinessTimezone(): string {
  return store.getters['DashboardModule/timezone']
}

export function getLocale(): string {
  return store.getters['DashboardModule/countryLocale']?.locale.replace('_', '-') ?? Locale.US
}

function isMatch(datetime: string | number | Date): boolean {
  return dayjs(datetime, 'YYYY-MM-DD', true).isValid()
}

export function toLocalTimeFormat(datetime: string | number | Date, timezone?: string): string {
  return isMatch(datetime)
    ? dayjs(datetime).format(`${DATE_TIME}`)
    : dayjs(datetime)
        .tz(timezone ?? getBusinessTimezone())
        .format(`${DATE_TIME}`)
}

export function toLocalTime(time: string | undefined): string {
  if (time === undefined) {
    return ''
  }

  const [hours, minutes] = time.split(':')
  const date = new Date()
  date.setUTCHours(Number(hours))
  date.setUTCMinutes(Number(minutes))
  date.setUTCSeconds(0)
  date.setUTCMilliseconds(0)

  return toLocalTimeFormat(date, 'UTC')
}
export function validDateRange(dateRange?: [Date, Date]): [Date, Date] {
  const today = new Date()

  return dateRange ?? [new Date(1900, 0, 1), new Date(today.getFullYear() + 1, 0, 1)]
}

export function dateRangeToOneYearPastFromToday(): [Date, Date] {
  const today = new Date()
  const dateRange = <[Date, Date]>[new Date(1900, 0, 1), new Date(today.getFullYear() + 1, 0, 1)]
  return validDateRange(dateRange)
}

export function dateRangeToToday(): [Date, Date] {
  const today = new Date()
  const dateRange = <[Date, Date]>[new Date(1900, 0, 1), new Date(today)]
  return validDateRange(dateRange)
}

export function toCalendarDateTimeFormat(datetime: string | number | Date | undefined): string {
  if (datetime === undefined) {
    return ''
  }

  return isMatch(datetime)
    ? dayjs(datetime).format('YYYY-MM-DD HH:mm:ss')
    : dayjs(datetime).tz(getBusinessTimezone()).format('YYYY-MM-DD HH:mm:ss')
}

export function formatTimeToUTC(
  datetime: string | number | Date,
  timezone = getBusinessTimezone(),
) {
  return dayjs.tz(datetime, timezone).utc().toISOString()
}

export function formatDateToTimezone(datetime: string, timezone?: string) {
  return dayjs(datetime)
    .tz(timezone ?? getBusinessTimezone())
    .format(`${SHORT_DATE}, ${DATE_TIME}`)
}

export function formatDateToTimezoneCalendar(datetime: string, timezone: string) {
  return dayjs(datetime).tz(timezone).format('YYYY-MM-DD HH:mm:ss')
}

export function toDateTimeFormat(datetime: string | number | Date): string {
  return dayjs(datetime).format(`${SHORT_DATE}, ${DATE_TIME}`)
}

// Functions from useDate
export const enum ServerDateFormat {
  Date = 'date',
  DateTime = 'datetime',
  DateTimeZ = 'datetimez',
  Time = 'time',
  TimeZ = 'timez',
}

// server accepts this format, so send it like this
export function getServerDateFormat(type?: ServerDateFormat) {
  switch (type) {
    case ServerDateFormat.Date:
      return 'YYYY-MM-DD'
    case ServerDateFormat.DateTime:
      return 'YYYY-MM-DD HH:mm:ss'
    case ServerDateFormat.DateTimeZ:
      return 'YYYY-MM-DD HH:mm:ss Z'
    case ServerDateFormat.Time:
      return 'HH:mm:ss'
    case ServerDateFormat.TimeZ:
      return 'HH:mm:ss Z'
    default:
      return 'YYYY-MM-DD HH:mm:ss'
  }
}

export function convertUtcToTimezone(date: string, timezone = getBusinessTimezone()) {
  return dayjs.utc(date).tz(timezone).format(getServerDateFormat())
}

export function convertTimezoneToUtc(date: string, timezone = getBusinessTimezone()) {
  return dayjs.tz(date, timezone).utc().format(getServerDateFormat())
}

export function dateFormat(short = true) {
  if (short) return 'M/D/YY'
  return 'M/D/YYYY'
}

export const getDateFormatFromLocale = (locale = getLocale()) => {
  const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
    switch (part.type) {
      case 'day':
        return 'D'.repeat(part.value.length)
      case 'month':
        return 'M'.repeat(part.value.length)
      case 'year':
        return 'Y'.repeat(part.value.length)
      case 'literal':
        return part.value
      default:
        return 'M/D/YY'
    }
  }

  return new Intl.DateTimeFormat(locale, { dateStyle: 'short' })
    .formatToParts(new Date('2025-01-01'))
    .map(getPatternForPart)
    .join('')
}

export const getTimeFormatFromLocale = (locale = getLocale()) => {
  const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
    switch (part.type) {
      case 'hour':
        return 'h'.repeat(part.value.length)
      case 'minute':
        return 'm'.repeat(part.value.length)
      case 'second':
        return 's'.repeat(part.value.length)
      case 'dayPeriod':
        return 'A'
      case 'literal':
        return part.value
      default:
        return 'h:mm A'
    }
  }

  return new Intl.DateTimeFormat(locale, { timeStyle: 'short' })
    .formatToParts(new Date('2025-01-01T13:34:56'))
    .map(getPatternForPart)
    .join('')
}

export function timeFormat(seconds = false) {
  if (seconds) {
    return 'h:mm:ss A'
  }
  return 'h:mm A'
}

export function dateTimeFormat() {
  return dateFormat() + ' ' + timeFormat()
}

export function getDateTimeFormatFromLocale() {
  return getDateFormatFromLocale() + ' ' + getTimeFormatFromLocale()
}

export function toDateFromTimestamp(datetime: number): string {
  return dayjs.unix(datetime).format()
}

// TODO Review:
// - All server dates above and figure out how to reduce their various formats

// Dates can be separated by their use:
// - Dates displayed for users on the website, which should be localized (adjusted to locale format (US, UK, Australian dates, etc.)), but not necessarily adjusted to timezone
// - Dates sent to the server (various formats), but usually should be zulu time
// - Dates provided to various plugins and packages (various formats)

// USER DATES START

// New date formats using internationalization, so after providing a locale we have each different country's dates in their local format
// The dates and times can be formatted in 4 different types. Check DateTimeFormat enum

export const enum DateTimeFormat {
  Full = 'full',
  Long = 'long',
  Medium = 'medium',
  Short = 'short',
}

type genericIntlDateOptions = {
  datetime: string // Provided date
  dateOnly: boolean // If only date, then do not display time
  timeOnly: boolean // Return only time from the date
  useTimezone: boolean // If timezone should be used at all
  dateStyle: DateTimeFormat // We mainly use short date style
  timeStyle: DateTimeFormat // We mainly use short time style
  locale: string // Locale from dashboard store locale
  timezone: string // If timezone not provided and it shouldn't be used, then use 'UTC'. Otherwise, the fn uses user's local timezone provided by the browser.
}

type genericIntlDateTimeOptions = {
  datetime: string
  dateStyle: DateTimeFormat
  timeStyle: DateTimeFormat
  locale: string
  timezone: string
}

type genericDateTimeWithoutTzOptions = {
  datetime: string
  dateStyle: DateTimeFormat
  timeStyle: DateTimeFormat
  locale: string
}

// Generic dates localized for each country by providing a locale

export function genericIntlDate(options: genericIntlDateOptions) {
  // Validate if the date is actually valid or if not valid date is provided 'new Date' fn will fail.
  if (!options.datetime || !dayjs(options.datetime).isValid()) {
    return
  }

  const date = new Date(options.datetime)

  return new Intl.DateTimeFormat(options.locale, {
    dateStyle: options.timeOnly ? undefined : options.dateStyle,
    timeStyle: options.dateOnly ? undefined : options.timeStyle,
    timeZone: options.timezone,
  }).format(date)
}

// Format DateTime with timezone
export function genericIntlDateTime(options: genericIntlDateTimeOptions) {
  if (!options.datetime || !dayjs(options.datetime).isValid()) {
    return
  }

  const date = new Date(options.datetime)

  return new Intl.DateTimeFormat(options.locale, {
    dateStyle: options.dateStyle,
    timeStyle: options.timeStyle,
    timeZone: options.timezone,
  }).format(date)
}

// Format DateTime
export function genericDateTimeWithoutTz(options: genericDateTimeWithoutTzOptions) {
  if (!options.datetime || !dayjs(options.datetime).isValid()) {
    return
  }

  const date = new Date(options.datetime)

  return new Intl.DateTimeFormat(options.locale, {
    dateStyle: options.dateStyle,
    timeStyle: options.timeStyle,
    timeZone: 'UTC',
  }).format(date)
}

// Date function names are comprised of:
// - Whether the date is converted to timezone (local)
// - Formatting type of the date (full, long, etc., look at DateTimeFormat for variations)
// - Localization country code in ISO2 format (US, GB, AU, LT, etc.)
// - Is it the date or dateTime

// Generate localized dates only, no time
export function localShortDate(
  date: string,
  timezone = getBusinessTimezone(),
  locale = getLocale(),
): string | undefined {
  return genericIntlDate({
    datetime: date,
    dateOnly: true,
    timeOnly: false,
    useTimezone: true,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: locale,
    timezone: timezone,
  })
}

// Generate localized dates only, no time and timezone used
export function shortDate(date: string, locale = getLocale()): string | undefined {
  return genericIntlDate({
    datetime: date,
    dateOnly: true,
    timeOnly: false,
    useTimezone: false,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: locale,
    timezone: 'UTC',
  })
}

// Generate localized dates with time using timezone
export function localShortDateTime(
  date: string,
  timezone = getBusinessTimezone(),
  locale = getLocale(),
): string | undefined {
  return genericIntlDateTime({
    datetime: date,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: locale,
    timezone: timezone,
  })
}

export function shortDateTime(date: string, locale = getLocale()): string | undefined {
  return genericDateTimeWithoutTz({
    datetime: date,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: locale,
  })
}

export function localShortTime(
  date: string,
  timezone = getBusinessTimezone(),
  locale = getLocale(),
): string | undefined {
  return genericIntlDate({
    datetime: date,
    dateOnly: false,
    timeOnly: true,
    useTimezone: true,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: locale,
    timezone: timezone,
  })
}

export function shortTime(date: string, locale = getLocale()): string | undefined {
  return genericIntlDate({
    datetime: date,
    dateOnly: false,
    timeOnly: true,
    useTimezone: false,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: locale,
    timezone: 'UTC',
  })
}

export function shortUsDateTime(date: string, timezone?: string): string | undefined {
  return genericIntlDate({
    datetime: date,
    dateOnly: false,
    timeOnly: false,
    useTimezone: true,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: 'en-US',
    timezone: timezone ? timezone : 'UTC',
  })
}

export function shortUsDate(date: string, timezone?: string): string | undefined {
  return genericIntlDate({
    datetime: date,
    dateOnly: true,
    timeOnly: false,
    useTimezone: true,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: 'en-US',
    timezone: timezone ? timezone : 'UTC',
  })
}

export function shortUsTime(date: string, timezone?: string): string | undefined {
  return genericIntlDate({
    datetime: date,
    dateOnly: false,
    timeOnly: true,
    useTimezone: true,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: 'en-US',
    timezone: timezone ? timezone : 'UTC',
  })
}
// USER DATES END
