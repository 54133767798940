import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { FeatureEnum } from '@/enums/FeatureEnum'
import useCurrentBusiness from '@/composables/useCurrentBusiness'

export const featureExistsInFeatures = (
  requiredFeature: string | string[],
  existingFeatureNames: string[],
) => {
  if (!Array.isArray(requiredFeature)) {
    requiredFeature = [requiredFeature]
  }

  return requiredFeature.every((rf) => existingFeatureNames.includes(rf))
}

const useFeatures = () => {
  const { business } = useCurrentBusiness()
  const route = useRoute()

  const features = computed(() => business.value?.features ?? [])

  const feature = computed(() =>
    features.value.find(
      (f) =>
        [FeatureEnum.LMS, FeatureEnum.EMS].includes(f.system_name as FeatureEnum) &&
        route.path.includes(f.system_name),
    ),
  )

  // default to empty string to keep it a string type and so it doesn't pass truth check. In reality, it should be undefined
  const featureId = computed(() => feature.value?.id ?? '')

  const featureNames = computed(() => features.value.map((f) => f.system_name))

  const lmsFeatureId = computed(() => {
    const lmsFeature = features.value.find((f) => f.system_name === FeatureEnum.LMS)

    return lmsFeature ? lmsFeature.id : undefined
  })

  const emsFeatureId = computed(() => {
    const emsFeature = features.value.find((f) => f.system_name === FeatureEnum.EMS)

    return emsFeature ? emsFeature.id : undefined
  })

  const intelliformsFeatureId = computed(() => {
    const intelliformsFeature = features.value.find(
      (f) => f.system_name === FeatureEnum.INTELLIFORMS,
    )

    return intelliformsFeature ? intelliformsFeature.id : undefined
  })

  const aiFeatureId = computed(() => {
    const aiFeature = features.value.find((f) => f.system_name === FeatureEnum.AI)

    return aiFeature ? aiFeature.id : undefined
  })

  const isEmsFeature = computed(() => feature.value?.system_name === FeatureEnum.EMS)
  const isLmsFeature = computed(() => feature.value?.system_name === FeatureEnum.LMS)

  const featureExists = (requiredFeature: string | string[]) =>
    featureExistsInFeatures(requiredFeature, featureNames.value)

  const isFeature = (featureName: string) => feature.value?.system_name === featureName

  // TODO - add marketplace feature?
  return {
    features,
    featureExists,
    isFeature,
    feature,
    featureId,
    lmsFeatureId,
    emsFeatureId,
    intelliformsFeatureId,
    aiFeatureId,
    isEmsFeature,
    isLmsFeature,
  }
}

export default useFeatures
