import { DataFieldResponse } from '@/api/Business/Lead/lead'
import { GoogleAddress } from '@/shared'
import { FileUploadItemResponseDTO } from '@/dto/common/files'
import { FeatureResponseDto } from '@/dto/BackOffice/Features/Response'
import { Audit } from '@/dto/common/audit'
import { CommonCountryResponseDto } from '@/dto/common/Country/Response'

export type BusinessListResponse = {
  id: string
  slug: string
  type: string | null
  title: string | null
  data_fields?: DataFieldResponse[]
}

export type BusinessItemResponse = {
  id: string
  slug: string
  type: string | null
  title: string | null
  country: CommonCountryResponseDto | null
  features: FeatureResponseDto[] // TODO - business?.features are not returned anymore in import { getBusiness } from "@/api/Business". But it does return in backoffice getBusiness call
  referrer_code: string | null
  promo_codes: string[] | null
  phone: string | null
  address: GoogleAddress | null
  website: string | null
  how_did_you_hear_about_us: string | null
  child_care_management_software: string | null
  logo: FileUploadItemResponseDTO | null
  data_fields: DataFieldResponse[]
  audit: Audit | null
  created_at: string
  updated_at: string
  deleted_at: string | null
}

export type BusinessCreateResponseDto = {
  id: 'string'
  type: 'string'
  slug: 'string'
  referrer_code: 'string'
  data_fields: DataFieldResponse[]
  promo_codes: string[]
}

export default class BusinessDto {
  public id: string
  public type: string | null = null
  public features: FeatureResponseDto[] = []
  public logo: FileUploadItemResponseDTO | null = null
  public dataFields: DataFieldResponse[] = []
  public country: CommonCountryResponseDto | null = null
  public audit: Audit | null = null
  public createdAt: Date | null = null
  public updatedAt: Date | null = null
  public deletedAt: Date | null = null

  constructor(
    id: string,
    public slug: string,
  ) {
    this.id = id
  }
}

export function transformBusinessResponseToDto(
  businessResponse: BusinessItemResponse,
): BusinessDto {
  const businessDto = new BusinessDto(businessResponse.id, businessResponse.slug)
  businessDto.type = businessResponse.type
  businessDto.features = businessResponse.features
  businessDto.logo = businessResponse.logo
  businessDto.dataFields = businessResponse.data_fields
  businessDto.country = businessResponse.country ?? null
  businessDto.audit = businessResponse.audit ?? null
  businessDto.createdAt =
    null !== businessResponse.created_at ? new Date(businessResponse.created_at) : null
  businessDto.updatedAt =
    null !== businessResponse.updated_at ? new Date(businessResponse.updated_at) : null
  businessDto.deletedAt =
    null !== businessResponse.deleted_at ? new Date(businessResponse.deleted_at) : null

  return businessDto
}
