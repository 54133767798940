import { RouteList } from '@/routes/RouteList'

export const RouteWhiteList = [
  RouteList.PUBLIC.INTELLIFORMS.REGISTER.NAME,
  RouteList.PUBLIC.INTELLIFORMS.START.NAME,
  RouteList.PUBLIC.INTELLIFORMS.DOCUMENT.NAME,
  RouteList.PUBLIC.INTELLIFORMS.PAYMENT.NAME,
  RouteList.PUBLIC.INTELLIFORMS.EXPIRED.NAME,
  RouteList.PUBLIC.INTELLIFORMS.NOT_FOUND.NAME,
  RouteList.PUBLIC.UNSUBSCRIBE.NAME,
  RouteList.PUBLIC.SCHEDULER.NAME,
  RouteList.PUBLIC.SCHEDULER_INLINE.NAME,
  RouteList.MISC.ERROR.NAME,
]
